import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import { BsChevronCompactDown, BsFillInfoSquareFill } from "react-icons/bs";

export default function BlockCustom() {

    const { rpdata } = useContext(GlobalDataContext);
    return (
        <section className="w-full py-10 flex justify-center items-center bg-[#080a1d]">
            <div className="w-[90%] flex md:flex-row flex-col gap-5">
                <div className="md:w-[30%] w-[95%] h-auto flex flex-col items-center justify-center">
                    <img
                        src="https://firebasestorage.googleapis.com/v0/b/imagenes-backup.appspot.com/o/MarkCar%2Fautobahn-logo%402x.png?alt=media&token=8d8b0b2c-dba4-4f54-8b55-3c302bda9b7c"
                        alt="placeholder"
                        className="w-full h-auto"
                    />
                    <h2 className='text-center text-[28px]'>{rpdata?.dbSlogan?.[9]?.slogan}</h2>
                </div>
                <div className="md:w-3/4 w-full justify-center flex h-auto">
                    <div className="items-center justify-center flex md:flex-row flex-col  md:space-x-6 space-x-0 space-y-6 md:space-y-0">
                        <div
                            className="flex flex-col gap-2 relative z-[8] rounded-lg p-5 text-white before:w-full before:h-full before:absolute before:inset-0 before:rounded-lg before:z-[-1] before:bg-[#00000079] group"
                            tabindex="1"
                            style={{
                                backgroundImage: `url(${rpdata?.works?.[0]?.gallery})`,
                                backgroundSize: 'cover',
                                backgroundPosition: '23% 18%'
                            }}
                        >
                            <div className="flex cursor-pointer items-center justify-between relative z-20">
                                <span className='text-white font-bold '> <BsFillInfoSquareFill />{rpdata?.works?.[0]?.name}</span>
                                <BsChevronCompactDown className='transition-all duration-500 md:group-focus:-rotate-180 group-focus:-rotate-90' />
                            </div>
                            <div className="overflow-y-hidden invisible md:h-[350px] h-[100px] md:w-[100px] w-[300px] items-center opacity-0 transition-all group-focus:visible md:group-focus:w-[350px] group-focus:w-[300px] group-focus:h-[350px] group-focus:opacity-100 group-focus:duration-1000">
                                {rpdata?.works?.[0]?.description}
                            </div>
                        </div>

                        <div
                            className="flex flex-col gap-2 relative z-[8] rounded-lg p-5 text-white before:w-full before:h-full before:absolute before:inset-0 before:rounded-lg before:z-[-1] before:bg-[#00000079] group"
                            tabindex="2"
                            style={{
                                backgroundImage: `url(${rpdata?.works?.[1]?.gallery})`,
                                backgroundSize: 'cover',
                                backgroundPosition: '25% 35%'

                            }}
                        >
                            <div className="flex cursor-pointer items-center justify-between">
                                <span className='text-white font-bold'> <BsFillInfoSquareFill /> {rpdata?.works?.[1]?.name} </span>
                                <BsChevronCompactDown className='transition-all duration-500 group-focus:-rotate-180' />
                            </div>
                            <div className="overflow-y-hidden invisible md:h-[350px] h-[100px] md:w-[100px] w-[300px] items-center opacity-0 transition-all group-focus:visible md:group-focus:w-[350px] group-focus:w-[300px] group-focus:h-[350px] group-focus:opacity-100 group-focus:duration-1000">
                                {rpdata?.works?.[1]?.description}
                            </div>
                        </div>

                        <div
                            className="flex flex-col gap-2 relative z-[8] rounded-lg p-5 text-white before:w-full before:h-full before:absolute before:inset-0 before:rounded-lg before:z-[-1] before:bg-[#00000079] group"
                            tabindex="3"
                            style={{
                                backgroundImage: `url(${rpdata?.works?.[2]?.gallery})`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center'

                            }}
                        >
                            <div className="flex cursor-pointer items-center justify-between">
                                <span className='text-white font-bold'> <BsFillInfoSquareFill /> {rpdata?.works?.[2]?.name} </span>
                                <BsChevronCompactDown className='transition-all duration-500 group-focus:-rotate-180' />
                            </div>
                            <div className="overflow-y-hidden invisible  md:h-[350px] h-[100px] md:w-[100px] w-[300px] items-center opacity-0 transition-all group-focus:visible md:group-focus:w-[350px] group-focus:w-[300px] group-focus:h-[350px] group-focus:opacity-100 group-focus:duration-1000">
                                {rpdata?.works?.[2]?.description}
                            </div>
                        </div>

                        <div
                            className="flex flex-col gap-2 relative z-[8] rounded-lg p-5 text-white before:w-full before:h-full before:absolute before:inset-0 before:rounded-lg before:z-[-1] before:bg-[#00000079] group"
                            tabindex="3"
                            style={{
                                backgroundImage: `url(${rpdata?.works?.[3]?.gallery})`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center'

                            }}
                        >
                            <div className="flex cursor-pointer items-center justify-between">
                                <span className='text-white font-bold'> <BsFillInfoSquareFill /> {rpdata?.works?.[3]?.name} </span>
                                <BsChevronCompactDown className='transition-all duration-500 group-focus:-rotate-180' />
                            </div>
                            <div className="invisible md:h-[350px] h-[100px] md:w-[100px] w-[300px] items-center opacity-0 transition-all group-focus:visible md:group-focus:w-[350px] group-focus:w-[300px] group-focus:h-[350px]  group-focus:opacity-100 group-focus:duration-1000 overflow-y-hidden">
                                {rpdata?.works?.[3]?.description}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );

}
